import * as React from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  ListItemButton,
  Card,
  CardMedia,
  useMediaQuery,
  Button,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddTaskIcon from '@mui/icons-material/AddTask';
import {
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import StoreIcon from '@mui/icons-material/Store';
import AppleIcon from '@mui/icons-material/Apple';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CategoryIcon from '@mui/icons-material/Category';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CloseIcon from '@mui/icons-material/Close';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LocalFloristRoundedIcon from '@mui/icons-material/LocalFloristRounded';
import Person from '@mui/icons-material/Person'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import './SideNavigation.scss';
import logo from '../assets/logo/logo.png';

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const navigationItems = [
  {
    text: 'Home',
    icon: <HomeIcon style={{ color: "#fff" }} />,
    path: '/Home',
  },
  {
    text: 'Allergy',
    icon: <CoronavirusIcon style={{ color: "#fff" }} />,
    path: '/Allergy',
  },
  {
    text: 'Ingredients',
    icon: <ListAltIcon style={{ color: "#fff" }} />,
    path: '/Ingredient',
  },
  // {
  //   text: 'Food',
  //   icon: <LocalDiningIcon style={{ color: "#fff" }} />,
  //   path: '/SignUp',
  // },
  {
    text: 'Store',
    icon: <StoreIcon style={{ color: "#fff" }} />,
    path: '/Store',
  },
  {
    text: 'Brand',
    icon: <ShowChartIcon style={{ color: "#fff" }} />,
    path: '/Brand',
  },
  {
    text: 'Not Permitted Brand',
    icon: <CloseIcon style={{ color: "#fff" }} />,
    path: '/NotPermittedBrand',
  },
  {
    text: 'Organ',
    icon: <SettingsAccessibilityIcon style={{ color: "#fff" }} />,
    path: '/Organ',
  },
  {
    text: 'Symptoms',
    icon: <WarningAmberIcon style={{ color: "#fff" }} />,
    path: '/Symptoms',
  },
  {
    text: 'Food Category',
    icon: <CategoryIcon style={{ color: "#fff" }} />,
    path: '/Category',
  },
  {
    text: 'Food SubCategory',
    icon: <AltRouteIcon style={{ color: "#fff" }} />,
    path: '/SubCategory',
  },
  {
    text: 'Products',
    icon: <FastfoodIcon style={{ color: "#fff" }} />,
    path: '/Products',
  },
  {
    text: 'Product Approvals',
    icon: <AddTaskIcon style={{ color: "#fff" }} />,
    path: '/ProductApprovals',
  },
  {
    text: 'Bulk Uploads',
    icon: <DriveFolderUploadIcon style={{ color: "#fff" }} />,
    path: '/BulkUploads',
  },
  {
    text: 'User Management',
    icon: <Person style={{ color: "#fff" }} />,
    path: '/Users',
  },
  // {
  //   text: 'About (Files)',
  //   icon: <BusinessCenterIcon style={{ color: "#fff" }} />,
  //   path: '/AboutFiles',
  // }
];
export default function SideNavigation() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [logoutModal, setLogoutModal] = React.useState(false)
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 460px)');

  const logout = () => {
    localStorage.removeItem('formData')
    setLogoutModal(false)
    navigate('/Login')
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, width: "100vw" }} className="sidenav">
      <AppBar position="fixed" color="secondary" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="end"
            sx={{
              marginRight: 5,
              ...(open && { color: "#70a132" }),
            }}
            className='menu-burger'
          >
            <MenuIcon style={{ fontSize: isSmallScreen ? 30 : 32, padding: isSmallScreen ? 5 : 0 }} />
          </IconButton>
          <div>
            {/* <Card> */}
            <CardMedia
              component="img"
              alt="Logo"
              image={logo}
              style={{ width: 130, margin: 0, padding: 0 }}
            />
            {/* </Card> */}
          </div>
          <div className="user-icon-top-bar">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle style={{ fontSize: isSmallScreen ? 30 : 35, }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose}>Change Password</MenuItem> */}
              <MenuItem onClick={() => setLogoutModal(true)}>Log Out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{ sx: { backgroundColor: "#70a132" } }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <BsChevronRight color="#fff" />
            ) : (
              <BsChevronLeft color="#fff" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <List>
            {navigationItems.map((item) => (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => navigate(item.path)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0, color: "#fff" }}>
                    {" "}
                    {item.text}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}


          </List>
        </Box>
      </Drawer>
      <Dialog
        open={logoutModal}
        onClose={() => setLogoutModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"LogOut"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => logout()}>Yes</Button>
          <Button onClick={() => setLogoutModal(false)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
